import React from 'react'

import Header from "../components/header"
import SEO from "../components/seo"

import '../styles/global.css'
import '../styles/about.css'

import Map from '../images/atl.svg'

function AboutPage() {
  function resizeComponent() {
    document.getElementById('wrapper').setAttribute('style', `height: ${window.innerHeight}px`);
  }

  React.useEffect(() => {
      resizeComponent();
      window.addEventListener('resize', resizeComponent);

      return function cleanup() {
          window.removeEventListener('resize', resizeComponent);
      }
  }, []); 

  return (
    <div id='wrapper' className='page-wrapper'>
      <SEO
        title="About Me"
      />
      <div className='about-wrapper'>
        <Header active={1} />
        <div id='about-1'>
          <div id='about-1-text'>
          <h2>Who am I?</h2>
            <p>
              Hello! I'm a frontend developer living in Atlanta, Georgia. I graduated from 
              Georgia Tech with a degree in Computational Media and have since worked as a 
              digital marketing contractor. My passion for making things clean, appealing and
              approachable has served me well in this capacity, but I feel like these skills and
              my desire to constantly learn have pushed me towards a career in web development.
            </p>
            <h2>What can I do?</h2>
            <ul>
              <li>HTML/CSS/Javascript</li>
              <li>Linux, Git, GitHub</li>
              <li>Java, Python, C#, GoLang, Node/Express</li>
              <li>Visual Design and Adobe Suite</li>
              <li>Written Communication</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutPage;